import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  mailchimpSubscribe,
  selectMailchimpStatus,
} from "../../features/mailchimp/mailchimpSlice";
import { STATUS } from "../../../shared/enums";
import { klaviyoSubscribe, selectKlaviyoStatus } from "../../features/klaviyo/klaviyoSlice";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const MailchimpSubscribeForm = ({ buttonText = "" }) => {
  const dispatch = useDispatch();
  const status = useSelector(selectKlaviyoStatus);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = new FormData(event.target);
    const email = form.get('email');
    dispatch(klaviyoSubscribe({ email }));
  };

  // TODO: clear default form attributes. It is unlikely that it will be submitted without javascript
  return (
    <form
      onSubmit={handleSubmit}
      action="https://milacares.us11.list-manage.com/subscribe/post?u=23d538285692e580df3c5b1de&amp;id=e9377ee817"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      className="validate mailchim-subscribe-form"
      target="_blank"
      noValidate
    >
      <div id="mc_embed_signup_scroll">
        <div className="mc-field-group subscribe-block__field">
          <label
            className="subscribe-block__field-label screen-reader-text"
            htmlFor="mce-EMAIL"
          >
            Enter your email address
          </label>
          <input
            type="email"
            defaultValue=""
            name="email"
            className="required email subscribe-block__field-input"
            id="mce-EMAIL"
            placeholder="your@email.com"
          />
        </div>
        <div id="mce-responses" className="clear">
          <div
            className="response"
            id="mce-error-response"
            style={{ display: "none" }}
          />
          <div
            className="response"
            id="mce-success-response"
            style={{ display: "none" }}
          />
        </div>
        <div
          style={{ position: "absolute", left: "-5000px" }}
          aria-hidden="true"
        >
          <label
            htmlFor="b_23d538285692e580df3c5b1de_e9377ee817"
            className="screen-reader-text"
          >
            email response
          </label>
          <input
            type="text"
            name="b_23d538285692e580df3c5b1de_e9377ee817"
            tabIndex="-1"
            defaultValue=""
            id="b_23d538285692e580df3c5b1de_e9377ee817"
          />
        </div>
        <div className="subscribe-block__submit">
          <button
            type="submit"
            value="Subscribe"
            name="subscribe"
            id="mc-embedded-subscribe"
            className="subscribe-block__submit-button"
            disabled={status === STATUS.LOADING}
            aria-label="Subscribe"
          >
            {buttonText ? (
              buttonText
            ) : (
              <>
                <span className="screen-reader-text">Subscribe</span>
                <svg className="icon icon-line-arrow-thick">
                  <use xlinkHref="#icon-line-arrow-thick" />
                </svg>
              </>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default MailchimpSubscribeForm;
